<template>
    <v-sheet style="background: linear-gradient(0deg, rgba(241,241,241,1) 65%, rgba(255,255,255,1) 65%);" class="text-center fill-height overflow-hidden">
        <v-container class="title" style="min-width:100vw!important;z-index:9999999;position:fixed; color:white;background-color:#ff5100!important;">
            <v-btn absolute to="/app" dark icon left>
                <v-icon large> mdi-chevron-left</v-icon>
            </v-btn>
            <div style="font-size:18px;" v-if="$route.params.id=='efi'">EFI Industrial</div>
            <div style="font-size:18px;" v-if="$route.params.id=='unoferre'">UNO Ferre</div>
            <div style="font-size:18px;" v-if="$route.params.id=='unonegocios'">UNO Negocios</div>
            <div style="font-size:18px;" v-if="$route.params.id=='fgs'">FGS</div>
            <div style="font-size:21px;">{{total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</div>
        </v-container>
        <div class="mt-12 pt-12" style="background: linear-gradient(0deg, white 50px, #ff5100 50px);">
            <div style="max-width:150px!important; background-color:white;" class="mx-auto elevation-4 pa-2" v-if="$route.params.id!='fgs'">
                <v-img v-if="$route.params.id=='efi'" src="/files/efiindustrial.svg"></v-img>
                <v-img v-if="$route.params.id=='unoferre'" src="/files/unoferre.png"></v-img>
                <v-img v-if="$route.params.id=='unonegocios'" src="/files/unonegocios.png"></v-img>
            </div>
        </div>
        <!-- Filtro -->
        <v-row v-if="filtro==true" class="px-8 mt-1">
            <!-- Filtrar por Fecha desde -->
            <v-col cols="6" class="pb-1">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="fechadeFilterValue" label="Desde" prepend-icon="mdi-calendar-today" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="fechadeFilterValue" @input="menu = false"></v-date-picker>
                </v-menu>
            </v-col>
            <!-- Filtrar por Fecha hasta -->
            <v-col cols="6" class="pb-2">
                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                <template v-slot:activator="{ on }">
                    <v-text-field v-model="fechaaFilterValue" label="Hasta" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker color="primary" v-model="fechaaFilterValue" @input="menu1 = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0">
                <v-autocomplete class="mt-0 pt-0" clearable v-model="conceptoFilterValue" :items="conceptosList" item-text="concepto" item-value="id" label="Concepto">
                    <template slot="no-data">
                        No existen conceptos relacionados.
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-tabs centered class="mb-12 pb-12">
            <v-tabs-slider></v-tabs-slider>
            <v-tab>Todo</v-tab>
            <v-tab-item>
                <v-card flat>
                    <div style="text-align:left; background-color:#f2f2f2!important;">
                        <div class="pa-4">Movimientos</div>
                        <v-expansion-panels>
                            <v-expansion-panel v-for="movimiento in movimientos('todo')">
                                <v-expansion-panel-header>
                                    <template v-slot:actions>
                                        <v-icon>
                                        </v-icon>
                                    </template>
                                    <v-row class="pa-0">
                                        <v-col cols="7">
                                            <div>{{concepto(movimiento.concepto)}}</div>
                                            <br>
                                            <p class="mb-0">{{formatoFecha(movimiento.fecha)}}</p>
                                        </v-col>
                                        <v-col cols="4"  style="text-align:right;">
                                            <strong v-if="movimiento.monto<0">{{(movimiento.monto).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                            <strong style="color:green;" v-if="movimiento.monto>0">+ {{(movimiento.monto).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                            <br>
                                            <v-btn v-if="movimiento.recibido==1 && currentUser.email=='fergo' || currentUser.email=='fgs' || currentUser.email=='mauricio'" @click="aprobarMovimiento(movimiento)" icon><v-icon>mdi-check</v-icon></v-btn>
                                            <v-btn v-else-if="movimiento.recibido==1" icon><v-icon>mdi-check</v-icon></v-btn>
                                            <v-btn v-else icon><v-icon color="primary"> mdi-check-all</v-icon></v-btn>
                                        </v-col>
                                        <v-col cols="1" class="vertical">
                                            <strong>
                                            {{acumulado(movimiento.id).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                                            </strong>
                                        </v-col>
                                        <!--v-btn @click="eliminarMovimiento(movimiento)">eliminar</v-btn-->
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content v-if="currentUser.email=='fergo'">
                                    <v-row>
                                        <v-col cols="10">
                                            {{movimiento.nota}}
                                        </v-col>
                                        <v-col cols="2">
                                        <v-divider style="border:none!important;"></v-divider>
                                            <v-btn right icon @click="eliminarMovimiento(movimiento)" >
                                                <v-icon color="red"> mdi-close</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                                <v-expansion-panel-content v-else>
                                    {{movimiento.nota}}
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <div style="text-align:center;" class="pt-6">
                            <strong>Total: </strong> {{(totalFiltrado('todo').toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))}}
                            <br>
                            <br>
                        </div>
                    </div>
                </v-card>
            </v-tab-item>

            <v-tab>Gastos</v-tab>
            <v-tab-item>
                <v-card flat>
                    <div style="text-align:left; background-color:#f2f2f2!important;">
                        <div class="pa-4">Movimientos</div>
                        <v-expansion-panels>
                            <v-expansion-panel v-for="movimiento in movimientos('gasto')">
                                <v-expansion-panel-header>
                                    <template v-slot:actions>
                                        <v-icon>
                                        </v-icon>
                                    </template>
                                    <v-row>
                                        <v-col cols="8">
                                            <div>{{concepto(movimiento.concepto)}}</div>
                                            <br>
                                            <p class="mb-0">{{formatoFecha(movimiento.fecha)}}</p>
                                        </v-col>
                                        <v-col cols="4"  style="text-align:right;">
                                            <strong v-if="movimiento.monto<0">{{(movimiento.monto).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                            <strong style="color:green;" v-if="movimiento.monto>0">+ {{(movimiento.monto).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                            <br>
                                            <v-btn v-if="movimiento.recibido==1" @click="aprobarMovimiento(movimiento)" icon><v-icon>mdi-check</v-icon></v-btn>
                                            <v-btn v-else icon><v-icon color="primary"> mdi-check-all</v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    {{movimiento.nota}}
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <div style="text-align:center;" class="pt-6">
                                <strong>Total: </strong> {{(totalFiltrado('gasto').toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))}}
                                <br>
                                <br>
                            </div>
                        </v-expansion-panels>
                    </div>
                </v-card>
            </v-tab-item>
            <v-tab>Ingresos</v-tab>
            <v-tab-item>
                <v-card flat>
                    <div style="text-align:left; background-color:#f2f2f2!important;">
                        <div class="pa-4">Movimientos</div>
                        <v-expansion-panels>
                            <v-expansion-panel v-for="movimiento in movimientos('ingreso')">
                                <v-expansion-panel-header>
                                    <template v-slot:actions>
                                        <v-icon>
                                        </v-icon>
                                    </template>
                                    <v-row>
                                        <v-col cols="8">
                                            <div>{{concepto(movimiento.concepto)}}</div>
                                            <br>
                                            <p class="mb-0">{{formatoFecha(movimiento.fecha)}}</p>
                                        </v-col>
                                        <v-col cols="4"  style="text-align:right;">
                                            <strong v-if="movimiento.monto<0">{{(movimiento.monto).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                            <strong style="color:green;" v-if="movimiento.monto>0">+ {{(movimiento.monto).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                            <br>
                                            <v-btn v-if="movimiento.recibido==1" @click="aprobarMovimiento(movimiento)" icon><v-icon>mdi-check</v-icon></v-btn>
                                            <v-btn v-else icon><v-icon color="primary"> mdi-check-all</v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    {{movimiento.nota}}
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <div style="text-align:center;" class="pt-6">
                            <strong>Total: </strong> {{(totalFiltrado('ingreso').toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))}}
                            <br>
                            <br>
                        </div>
                    </div>
                </v-card>
            </v-tab-item>
        </v-tabs>
        
        <!-- absolute -->
        <div style="z-index: 9; position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: #ff5100;">
            <v-row class="text-center px-4 py-2" style="color:white;">
            <v-col style="cursor:pointer;" cols="4" @click="filtro=!filtro">
                <v-icon dark>mdi-filter</v-icon>
                <br>Filtrar
            </v-col>
            <v-col @click="movimiento(-1)" style="cursor:pointer;" cols="4">
                <v-icon small dark> mdi-minus</v-icon><v-icon dark>mdi-currency-usd</v-icon>
                <br>Gasto
            </v-col>
            <v-col @click="movimiento(1)" style="cursor:pointer;" cols="4">
                <v-icon small dark>mdi-plus</v-icon><v-icon dark>mdi-currency-usd</v-icon>
                <br>Ingreso
            </v-col>
            </v-row>
        </div>
        <v-dialog v-model="dialogMovimiento" max-width="400px">
            <altaMovimiento v-bind:datosMovimiento="datosMovimiento" @cerrarMovimiento="cerrarMovimiento"></altaMovimiento>
        </v-dialog>
    </v-sheet>
</template>
<script>
import axios from "axios";
import altaMovimiento from "../components/dialogs/altaMovimiento"
export default {
    data () {
        return {
            dialogMovimiento: false,
            datosMovimiento:{
                tipo:'', usuario:'', empresa:''
            },
            fechaaFilterValue:'',
            fechadeFilterValue:'',
            conceptoFilterValue:'',
            menu:false,
            menu1:false,
            filtro:false,
            editMovimiento:''
        }
    },
    mounted(){
        var hoy = new Date();
        this.fechadeFilterValue = new Date(hoy.getFullYear(), hoy.getMonth(), 1).toISOString().slice(0,10);
        this.fechaaFilterValue = new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0).toISOString().slice(0,10);
    },
    computed:{
        conceptosList(){
            return this.$store.state.concepto.conceptos;
        },
        perrosList(){
            return this.$store.state.movimiento.movimientos
        },
        movimientosList(){
            if(this.conceptoFilterValue!='' && this.conceptoFilterValue!=null && this.conceptoFilterValue!=undefined){
                return this.perrosList
                .filter(movimiento => movimiento.concepto == this.conceptoFilterValue)
                .filter(movimiento => movimiento.empresa == this.$route.params.id)
                .filter(movimiento => new Date(movimiento.fecha.replace(/\s/, 'T')).toISOString().slice(0,10) >= this.fechadeFilterValue)
                .filter(movimiento => new Date(movimiento.fecha.replace(/\s/, 'T')).toISOString().slice(0,10) <= this.fechaaFilterValue)
            }else{
                return this.perrosList
                .filter(movimiento => movimiento.empresa == this.$route.params.id)
                .filter(movimiento => new Date(movimiento.fecha.replace(/\s/, 'T')).toISOString().slice(0,10) >= this.fechadeFilterValue)
                .filter(movimiento => new Date(movimiento.fecha.replace(/\s/, 'T')).toISOString().slice(0,10) <= this.fechaaFilterValue)
            }
        },
        total(){
            var movimientos = this.perrosList.filter(movimiento => movimiento.recibido != 1).filter(movimiento => movimiento.empresa == this.$route.params.id)
            var suma = movimientos.reduce((a, b) => a + (b['monto'] || 0), 0)
            return suma
        },
        currentUser:{
            get(){
                return this.$store.state.currentUser.user
            }
        },
    },
    components:{ 
      'altaMovimiento':altaMovimiento,
    },
    created(){
        this.$store.dispatch('movimiento/getMovimientos')
        this.$store.dispatch('concepto/getConceptos')
        this.$store.dispatch('currentUser/getUser')
    },
    methods:{
        acumulado(id){
            return Number(this.total - this.totalFiltradoSa('todo') + this.movimientosList.filter(movimiento=>movimiento.id <= id).reduce((a, b) => a + (b['monto'] || 0), 0))


        },
        totalFiltradoSa(tipo){
            var movimientos = this.movimientos(tipo).filter(movimiento => movimiento.empresa == this.$route.params.id)
            var suma = movimientos.reduce((a, b) => a + (b['monto'] || 0), 0)
            return suma
        },
        totalFiltrado(tipo){
            var movimientos = this.movimientos(tipo).filter(movimiento => movimiento.recibido != 1).filter(movimiento => movimiento.empresa == this.$route.params.id)
            var suma = movimientos.reduce((a, b) => a + (b['monto'] || 0), 0)
            return suma
        },
        movimiento(tipo){
            this.datosMovimiento.tipo = tipo
            this.datosMovimiento.usuario = this.currentUser.id
            this.datosMovimiento.empresa = this.$route.params.id
            this.dialogMovimiento = true
        },
        cerrarMovimiento: function(params) {
            this.$store.dispatch('movimiento/getMovimientos')
            this.dialogMovimiento = params;
        },
        aprobarMovimiento(movimiento){
            if (confirm('¿Seguro que desea autorizar "' + this.concepto(movimiento.concepto) + '" por ' + movimiento.monto.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}) + '?')) {            
                this.editedIndex = this.movimientosList.indexOf(movimiento)
                this.editMovimiento = Object.assign({}, movimiento)
                this.editMovimiento.recibido = 2
                axios.put('https://unoweb.uno/api/v1/movimiento/actualizar',Object.assign(this.editMovimiento)).then((response) => {
                    Object.keys(this.editMovimiento).forEach(key => {
                        return (this.editMovimiento[key] = "");
                    });
                    this.$store.dispatch('movimiento/getMovimientos')
                })
            }
        },
        eliminarMovimiento(movimiento){
            if (confirm('¿Seguro que desea eliminar "' + this.concepto(movimiento.concepto) + '" por ' + movimiento.monto.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}) + '?')) {
                axios.delete('https://unoweb.uno/api/v1/movimiento/borrar/'+movimiento.id).then((response) => {
                    this.$store.dispatch('movimiento/getMovimientos')
                })
            }
        },
        concepto(id){
            return this.$store.state.concepto.conceptos.filter(concepto => concepto.id == id).map(concepto => concepto.concepto)[0]
        },
        formatoFecha(item){
            var fecha = new Date(item.replace(/\s/, 'T'));
            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return fecha.toLocaleDateString("es-ES", options)
            return item
        },
        movimientos(tipo){
            if(tipo=='gasto'){
                this.tipodefiltro='gasto'
                return this.movimientosList.filter(movimiento => movimiento.monto <= -1);
            }else if(tipo=='ingreso'){
                this.tipodefiltro='ingreso'
                return this.movimientosList.filter(movimiento => movimiento.monto >= 1);
            }else{
                this.tipodefiltro='todo'
                return this.movimientosList;
            }
        },
    }
  }
</script>
<style>
.v-item-group.theme--light.v-slide-group.v-tabs-bar.v-tabs-bar--is-mobile.primary--text{
    border-bottom:1px #e8e8e8 solid;
}
@media (max-width: 980px){
    .vertical{
        line-height: 2px; 
        background-color:#dedede; 
        writing-mode: vertical-rl;
        font-size:12px;
    }
}
</style>